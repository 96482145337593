import { render, staticRenderFns } from "./DocumentTemplateForm.vue?vue&type=template&id=24ec99d2&scoped=true&"
import script from "./DocumentTemplateForm.vue?vue&type=script&lang=js&"
export * from "./DocumentTemplateForm.vue?vue&type=script&lang=js&"
import style0 from "@/assets/sass/pages/document-template/document-template.scss?vue&type=style&index=0&id=24ec99d2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ec99d2",
  null
  
)

export default component.exports